<template>
  <div class="board">
    <div class="container">
      <div class="alert" role="alert">
      <router-link to="/Bors"><i class="fas fa-map-marked-alt"></i> zurück zur Übersicht</router-link>
      </div>
      <h1>Prozessbeschreibung</h1>
      <div class="row">
        <div class="col-md-7">
          <h2>Prozessdefinition</h2>
          <p>{{process.definition}}</p>

          <h2>Geltungsbereich</h2>
          <p>{{process.area}}</p>

          <h2>Regelung</h2>
          <p v-html="process.implementation"></p>

          <h2>Verantwortlichkeit</h2>
          <p v-html="process.responability"></p>

        </div>

        <div class="col-md-5">
          <h2 v-if="Object.keys(images).length != 0">Einblicke</h2>
          <b-container fluid class="p-4 bg-bright" v-if="Object.keys(images).length != 0">
            <b-row>
              <light-box ref="lightbox" :media="gallery" :show-light-box="false"></light-box>

              <b-col cols="3" :key="key" v-for="(img, key) in gallery" >
                <b-img @click="showGallery(key)" height="120px" thumbnail fluid v-lazy="img.src || img.thumb"></b-img>

              </b-col>
            </b-row>
          </b-container>


        <h2 v-if="Object.keys(attachments).length != 0">Anlagen</h2>
        <ul v-if="Object.keys(attachments).length != 0">
          <li :key="key" v-for="(img, key) in attachments"><a  :href="attachmentDir + key.substr(1)">{{key.substr(2).replaceAll("_", " ")}}</a></li>
        </ul>
        </div>
        </div>
      
    </div>

  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Fasthand&family=Lily+Script+One&family=Nanum+Pen+Script&display=swap');


.board {
  color:white;
}

h1 {
  margin-bottom:1em;
}

h2 {
    /*font-family: 'Nanum Pen Script', cursive;    color:gray;

  background-color:#c4c4c4;
  padding:0.3em;*/
  color:white;
  font-size:1.3em;
  font-weight:bold;
  margin-top:1.5em;
}

a {
color:white;

}
</style>
<script>
import processes from '../json/processes.json'
import LightBox from 'vue-image-lightbox'

export default {
  name: 'Process',
  components: {
    LightBox
  },
  props: ['id'],
  data () {
    return {
      processes: processes,
      images: {},
      attachments: {}
    }
  },
  computed: {
    gallery() {
      let img = [];
      if(Object.keys(this.images).length != 0) {

        Object.values(this.images).forEach(val => {
          img.push({ // For image
            thumb: val,
            src: val,
          });
        });


      }
      return img;
    },
    process() {
      return processes.filter(c => c.id == this.id)[0];
    },
    imageDir() {
      return "/processes/"+this.process['id']+"/Bilder";
    },
    attachmentDir() {
      return "/processes/"+this.process['id']+"/Anlage";
    }
  },
  mounted() {
    switch(this.process.id) {

      case 5:
        this.importAll(require.context( '../../public/processes/5/Bilder'));
        this.importAttachments(require.context( '../../public/processes/5/Anlage'));
        break;
    
      case 6:
        this.importAll(require.context( '../../public/processes/6/Bilder'));
        this.importAttachments(require.context( '../../public/processes/6/Anlage'));
        break;
      case 7:
        this.importAttachments(require.context( '../../public/processes/7/Anlage'));
        break;
      case 8:
        this.importAll(require.context( '../../public/processes/8/Bilder'));
        this.importAttachments(require.context( '../../public/processes/8/Anlage'));
        break;
      case 9:
        this.importAll(require.context( '../../public/processes/9/Bilder'));
        this.importAttachments(require.context( '../../public/processes/9/Anlage'));
        break;
      case 10:
        this.importAttachments(require.context( '../../public/processes/10/Anlage'));
        break;
      case 11:
        this.importAll(require.context( '../../public/processes/11/Bilder'));
        this.importAttachments(require.context( '../../public/processes/11/Anlage'));
        break; 
      case 12: 
        this.importAll(require.context( '../../public/processes/12/Bilder'));
        this.importAttachments(require.context( '../../public/processes/12/Anlage'));
        break;
      case 13:
        this.importAll(require.context( '../../public/processes/13/Bilder'));
        this.importAttachments(require.context( '../../public/processes/13/Anlage'));
        break;
      case 14:
        this.importAttachments(require.context( '../../public/processes/14/Anlage'));
        break;
      case 15:
        this.importAttachments(require.context( '../../public/processes/15/Anlage'));
        break;
        
      default:

        break;
    }
    
  },
  methods: {
    importAll(r) {
      var imgs = {}
      r.keys().forEach(key => (imgs[key] = r(key)))
      this.images = imgs
    },
    importAttachments(r) {
      var attachments = {}
      r.keys().forEach(key => (attachments[key] = r(key)))
      this.attachments = attachments
    },
    showGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  }
}
</script>
